export const state = () => ({
  triggersList: [
    {
      id: 1,
      name: 'Contact Birthday',
      execute_on: 'Trigger Date',
      trigger: 'Contact Date of Birth',
      attributes: {
        day: '0 Day(s) After',
        specific_time: '08:30 AM',
        recur: 'Repeat every 6 Month(s)',
      },
      last_sync: 'Today, 9 Jun 2020 at 9:41 AM',
    },
    {
      id: 2,
      name: 'Software Update',
      execute_on: 'Calendar Date',
      trigger: '31st of a month',
      attributes: {
        day: '1 Day(s) After',
        specific_time: '08:30 AM',
        recur: 'Repeat every 6 Month(s)',
      },
      last_sync: 'Today, 9 Jun 2020 at 9:41 AM',
    },
    {
      id: 3,
      name: 'Greeting Contact Update',
      execute_on: 'Trigger Date',
      trigger: 'Contact custom date fields',
      attributes: {
        day: '0 Day(s) After',
        specific_time: '01:00 PM',
        recur: 'Once only',
      },
      last_sync: 'Today, 9 Jun 2020 at 9:41 AM',
    },
  ],
})

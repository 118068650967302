import { getField, updateField } from 'vuex-map-fields'
import requests from '~/requests'
import {
  TOKOPEDIA_PRODUCTS,
  TOKOPEDIA_ORDERS,
  INTEGRATION_URL,
} from '@/assets/variables/endpoints'
import { validTokenCookie } from '~/utils/general'
import insertOrganizationIdToUrl from '~/utils/insertOrganizationIdToUrl'

export const state = () => ({
  tokpedProducts: [],
  tokpedOrders: [],
  selectedProduct: [],
  selectedOrder: [],
  isShowAttachedProduct: false,
  isShowAttachedInvoice: false,
})

export const getters = {
  getField,
}

export const actions = {
  getTokopediaProducts({ commit }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(`${TOKOPEDIA_PRODUCTS}`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit('UPDATE_TOKOPEDIA_PRODUCTS', res)
            resolve(res)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  retrieveTokopediaProductsInfinite({ commit }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(`${TOKOPEDIA_PRODUCTS}`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            if (res?.data?.length) {
              commit('PUSH_TOKOPEDIA_PRODUCTS', res)
            }
            resolve(res)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  getTokopediaOrders({ commit }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(`${TOKOPEDIA_ORDERS}`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit('UPDATE_TOKOPEDIA_ORDERS', res)
            resolve(res)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  async postConnectStore(_context, { payload, targetChannel }) {
    try {
      const additional = {
        headers: {
          Authorization: validTokenCookie(),
        },
      }
      return await this.$axios.post(
        `${insertOrganizationIdToUrl(
          `${INTEGRATION_URL}/${targetChannel}`,
          this.$auth.user.organization_id
        )}`,
        payload,
        additional
      )
    } catch (err) {
      throw err
    }
  },
  async putReconnectStore(_context, { payload, storeId }) {
    try {
      const additional = {
        headers: {
          Authorization: validTokenCookie(),
        },
      }
      return await this.$axios.put(
        `${insertOrganizationIdToUrl(
          INTEGRATION_URL,
          this.$auth.user.organization_id
        )}/${storeId}/status`,
        payload,
        additional
      )
    } catch (err) {
      throw err
    }
  },
  async fetchDisconnectStore(_context, payload) {
    try {
      const additional = {
        headers: {
          Authorization: validTokenCookie(),
        },
      }
      return await this.$axios.put(
        `${insertOrganizationIdToUrl(
          INTEGRATION_URL,
          this.$auth.user.organization_id
        )}/${payload.id}/status`,
        payload,
        additional
      )
    } catch (err) {
      throw err
    }
  },
  async fetchRemoveStore(_context, { storeId, targetChannel }) {
    try {
      const additional = {
        headers: {
          Authorization: validTokenCookie(),
        },
      }
      return await this.$axios.delete(
        `${insertOrganizationIdToUrl(
          INTEGRATION_URL,
          this.$auth.user.organization_id
        )}/${targetChannel}/${storeId}`,
        additional
      )
    } catch (err) {
      throw err
    }
  },
}

export const mutations = {
  updateField,
  UPDATE_TOKOPEDIA_PRODUCTS(state, payload) {
    state.tokpedProducts = payload.data
  },
  PUSH_TOKOPEDIA_PRODUCTS(state, payload) {
    state.tokpedProducts.push(...payload.data)
  },
  UPDATE_SELECTED_PRODUCTS(state, payload) {
    state.selectedProduct = payload
  },
  UPDATE_SELECTED_ORDER(state, payload) {
    state.selectedOrder = payload
  },
  CLEAR_SELECTED_PRODUCTS(state) {
    state.selectedProduct = []
  },
  CLEAR_SELECTED_ORDERS(state) {
    state.selectedOrder = []
  },
  REMOVE_SELECTED_PRODUCTS(state, payload) {
    state.selectedProduct.splice(payload, 1)
    if (!state.selectedProduct.length) {
      state.isShowAttachedProduct = false
    }
  },
  REMOVE_SELECTED_ORDER(state, payload) {
    state.selectedOrder.splice(payload, 1)
    if (!state.selectedOrder.length) {
      state.isShowAttachedInvoice = false
    }
  },
  UPDATE_TOKOPEDIA_ORDERS(state, payload) {
    state.tokpedOrders = payload.data
  },
  UPDATE_IS_SHOW_ATTACHED_PRODUCT(state, payload) {
    state.isShowAttachedProduct = payload
  },
  UPDATE_IS_SHOW_ATTACHED_INVOICE(state, payload) {
    state.isShowAttachedInvoice = payload
  },
  CLEAR_TOKOPEDIA_ORDERS_AND_PRODUCTS(state) {
    state.tokpedOrders = []
    state.tokpedProducts = []
  },
}

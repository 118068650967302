import { getField, updateField } from 'vuex-map-fields'
import requests from '../requests'
import {
  USER_URL,
  USER_AUTHENTICATION_URL,
  USER_CORE_URL,
} from '@/assets/variables/endpoints'

export const state = () => ({
  dataTable: [],
  ids: '',
  userDetail: {
    avatar: '',
    full_name: '',
    email: '',
    organization_id: '',
    role: '',
    extra: {
      type_access_inbox: '',
    },
    divisions: [],
  },
  info: {
    status: false,
    message: '',
  },
  resetToken: {
    id: null,
    status: true,
    message: '',
  },
  user: {
    agent: [],
    supervisor: [],
  },
  selectedRole: 'all',
})

export const getters = {
  getField,
}

export const actions = {
  getUsers({ commit, state }, params) {
    if (params.offset === 1) params.is_counted = true
    if (state.selectedRole !== 'all') params.role = state.selectedRole
    commit(
      'layouts/UPDATE_CONTENTLOADING',
      { type: 'table', status: true },
      { root: true }
    )
    requests.whatsapp
      .getService(USER_URL, params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          if (params.offset === 1)
            commit('layouts/SET_TOTAL_COUNT', res.meta.pagination.total, {
              root: true,
            })
          commit('layouts/SET_META', res.meta, { root: true })
          commit('SET_DATATABLE', res.data)
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { type: 'table', status: false },
            { root: true }
          )
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { type: 'table', status: false },
            { root: true }
          )
        }
      )
  },
  getUsersAdmin({ commit, state }, params) {
    if (params.offset === 1) params.is_counted = true
    if (state.selectedRole !== 'all') params.role = state.selectedRole
    commit(
      'layouts/UPDATE_CONTENTLOADING',
      { type: 'table', status: true },
      { root: true }
    )
    requests.whatsapp
      .getService(USER_CORE_URL, params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          if (params.offset === 1)
            commit('layouts/SET_TOTAL_COUNT', res.meta.pagination.total, {
              root: true,
            })
          commit('layouts/SET_META', res.meta, { root: true })
          commit('SET_DATATABLE', res.data)
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { type: 'table', status: false },
            { root: true }
          )
        },
        (err) => {
          if (err.error.messages[0].toLowerCase() !== 'expired token') {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { type: 'table', status: false },
              { root: true }
            )
          }
        }
      )
  },
  getUserList({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
      requests.whatsapp
        .getService(USER_CORE_URL, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            const data = {
              data: res.data,
              role: params.role,
            }
            commit('UPDATE_USER_LIST', data)
            commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
            resolve(res.meta.pagination)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
            reject(err)
          }
        )
    })
  },
  createUser({ commit, state, dispatch }, params) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    requests.whatsapp
      .postService(USER_URL, params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'success',
              message: 'Successfully Created!',
              item: '',
              callback: {
                text: 'OK',
                method: 'refreshUsers',
              },
            },
            { root: true }
          )
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        }
      )
  },
  createUserAdmin({ commit }, params) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    requests.whatsapp
      .postService(USER_CORE_URL, params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'success',
              message: 'Successfully Created!',
              item: '',
              callback: {
                text: 'OK',
                method: 'refreshUsersAdmin',
              },
            },
            { root: true }
          )
        },
        (err) => {
          let messages = err.error.messages[0]
          if (messages.type !== 'string') {
            messages = mappingUserErrorMessage(messages)
          }
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: messages,
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        }
      )
  },
  getUserDetail({ commit, state, dispatch }, params) {
    return new Promise((resolve, reject) => {
      commit(
        'layouts/UPDATE_CONTENTLOADING',
        { type: 'table', status: true },
        { root: true }
      )
      requests.whatsapp
        .getService(
          `${USER_URL}/${state.ids}`,
          {},
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            const data = {
              ...res.data,
              extra: {
                type_access_inbox: res.data?.extra?.type_access_inbox ?? 'all',
              },
            }
            commit('UPDATE_DETAIL', data)
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { type: 'table', status: false },
              { root: true }
            )
            commit('UPDATE_AVATAR', res.data.avatar.large.url)
            resolve(true)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { type: 'table', status: false },
              { root: true }
            )
            reject(err)
          }
        )
    })
  },
  getUserDetailAdmin({ commit, state }) {
    return new Promise((resolve, reject) => {
      commit(
        'layouts/UPDATE_CONTENTLOADING',
        { type: 'table', status: true },
        { root: true }
      )
      requests.whatsapp
        .getService(
          `${USER_CORE_URL}/${state.ids}`,
          {},
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            const data = {
              ...res.data,
              extra: {
                type_access_inbox: res.data?.extra?.type_access_inbox ?? 'all',
              },
            }
            commit('UPDATE_DETAIL', data)
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { type: 'table', status: false },
              { root: true }
            )
            commit('UPDATE_AVATAR', res.data.avatar.large.url)
            resolve(true)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { type: 'table', status: false },
              { root: true }
            )
            reject(err)
          }
        )
    })
  },
  deleteUser({ commit, state, dispatch }, params) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    requests.whatsapp
      .deleteService(`${USER_URL}/${params.id}`, params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'success',
              message: 'Successfully Deleted!',
              item: '',
              callback: {
                text: 'OK',
                method: 'refreshUsers',
              },
            },
            { root: true }
          )
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        }
      )
  },
  deleteUserAdmin({ commit }, params) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    requests.whatsapp
      .deleteService(`${USER_CORE_URL}/${params.id}`, params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'success',
              message: 'Successfully Deleted!',
              item: '',
              callback: {
                text: 'OK',
                method: 'refreshUsersAdmin',
              },
            },
            { root: true }
          )
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        }
      )
  },
  updateUser({ commit, state }, params) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    const myHeaders = new Headers()
    myHeaders.append('Authorization', this.$auth.getToken('hub'))

    const formdata = new FormData()
    formdata.append('id', state.userDetail.id)
    formdata.append('organization_id', state.userDetail.organization_id.id)
    formdata.append('full_name', state.userDetail.full_name)
    formdata.append('phone', state.userDetail.phone)
    formdata.append('email', state.userDetail.email)
    formdata.append('role', state.userDetail.role)
    if (params.isChatAccess && state.userDetail.role === 'supervisor')
      formdata.append(
        'type_access_inbox',
        state.userDetail.extra?.type_access_inbox ?? 'all'
      )
    if (params.editedAvatar) formdata.append('avatar', state.userDetail.avatar)

    const requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow',
    }

    fetch(`${USER_URL}/${params.id}`, requestOptions)
      .then((data) => data.json())
      .then((res) => {
        if (res.status === 'success') {
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
          commit(
            'layouts/DISPLAY_TOAST',
            { message: 'User Succesfully Updated!' },
            { root: true }
          )
        } else {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: '',
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        }
      })
      .catch((err) => {
        commit(
          'layouts/UPDATE_NOTIFICATION',
          {
            display: true,
            type: 'failed',
            message: 'Error!',
            item: 'code: ' + err,
            callback: {
              text: 'OK',
              method: 'closeNotification',
            },
          },
          { root: true }
        )
        commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
      })
  },
  updateUserAdmin({ commit, state }, params) {
    const divisionIds = state.userDetail.divisions.map((d) => d.id)
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    const myHeaders = new Headers()
    myHeaders.append('Authorization', this.$auth.getToken('hub'))

    const formdata = new FormData()
    formdata.append('id', state.userDetail.id)
    formdata.append('organization_id', state.userDetail.organization_id.id)
    formdata.append('full_name', state.userDetail.full_name)
    formdata.append('phone', state.userDetail.phone)
    formdata.append('email', state.userDetail.email)
    formdata.append('role', state.userDetail.role)
    if (params.isChatAccess && state.userDetail.role === 'supervisor')
      formdata.append(
        'type_access_inbox',
        state.userDetail.extra?.type_access_inbox ?? 'all'
      )
    divisionIds.forEach((id) => {
      formdata.append('division_ids[]', id)
    })
    if (params.editedAvatar) formdata.append('avatar', state.userDetail.avatar)

    const requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow',
    }

    fetch(`${USER_CORE_URL}/${params.id}`, requestOptions)
      .then((data) => data.json())
      .then((res) => {
        if (res.status === 'success') {
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
          commit(
            'layouts/DISPLAY_TOAST',
            { message: 'User Succesfully Updated!' },
            { root: true }
          )
        } else {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: '',
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        }
      })
      .catch((err) => {
        commit(
          'layouts/UPDATE_NOTIFICATION',
          {
            display: true,
            type: 'failed',
            message: 'Error!',
            item: 'code: ' + err,
            callback: {
              text: 'OK',
              method: 'closeNotification',
            },
          },
          { root: true }
        )
        commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
      })
  },
  resetPassword({ commit, state, dispatch }, params) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    requests.whatsapp
      .putService(`${USER_URL}/${params.id}/reset_password`, params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
          commit(
            'layouts/DISPLAY_TOAST',
            { message: 'Password Succesfully Changed!' },
            { root: true }
          )
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        }
      )
  },
  resetPasswordAdmin({ commit, state, dispatch }, params) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    requests.whatsapp
      .putService(`${USER_CORE_URL}/${params.id}/reset_password`, params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
          commit(
            'layouts/DISPLAY_TOAST',
            { message: 'Password Succesfully Changed!' },
            { root: true }
          )
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        }
      )
  },
  getForgotPassword({ commit }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(`${USER_AUTHENTICATION_URL}/forgot_password`, params, {
          Authorization: '',
        })
        .subscribe(
          (res) => {
            commit('UPDATE_INFO', {
              status: true,
              message:
                'You will receive an email with instructions on how to reset your password in a few minutes.',
            })
            resolve(true)
          },
          (err) => {
            commit('UPDATE_INFO', {
              status: false,
              message: '',
            })
            reject(err)
          }
        )
    })
  },
  getTokenValidation({ commit }, params) {
    requests.whatsapp
      .getService(`${USER_AUTHENTICATION_URL}/validate_token`, params, {
        Authorization: '',
      })
      .subscribe(
        (res) => {
          commit('UPDATE_RESET_TOKEN_STATUS', {
            id: res.data.id,
            status: true,
            message: '',
          })
        },
        (err) => {
          commit('UPDATE_RESET_TOKEN_STATUS', {
            status: false,
            message: err.error.messages[0],
          })
        }
      )
  },
  putResetPassword({ commit }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .putService(
          `${USER_AUTHENTICATION_URL}/${params.id}/reset_password`,
          params,
          { Authorization: '' }
        )
        .subscribe(
          (res) => {
            commit('UPDATE_INFO', {
              status: true,
              message:
                'Your password has been changed successfully, please login with a new password',
            })
            resolve(true)
          },
          (err) => {
            commit('UPDATE_INFO', {
              status: false,
              message: '',
            })
            reject(err)
          }
        )
    })
  },
  getUserInfinite({ commit, state }, params) {
    //  get user for QIS implementation
    commit(
      'layouts/UPDATE_CONTENTLOADING',
      { type: 'content', status: true },
      { root: true }
    )
    commit('SET_DATATABLE', [])
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(`${USER_CORE_URL}`, params.parameter, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { type: 'content', status: false },
              { root: true }
            )
            commit(
              'infinite/SET_META',
              { data: res.meta, source: params.source },
              { root: true }
            )
            commit('SET_DATATABLE', res.data)

            resolve(res.data)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            reject(err)
          }
        )
    })
  },
  getUserInfiniteByPage({ commit, state }, params) {
    //  get next page of user for QIS implementation
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(`${USER_CORE_URL}`, params.parameter, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit(
              'infinite/SET_META',
              { data: res.meta, source: params.source },
              { root: true }
            )
            commit('PUSH_DATATABLE', res.data)

            resolve(res.data)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            reject(err)
          }
        )
    })
  },
  getUserByEmail({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(`${USER_CORE_URL}/check_email`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            resolve(res)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
}

export const mutations = {
  updateField,
  SET_DATATABLE(state, payload) {
    state.dataTable = payload
  },
  PUSH_DATATABLE(state, payload) {
    payload.forEach((data) => {
      state.dataTable.push(data)
    })
  },
  UPDATE_DETAIL(state, payload) {
    state.userDetail = payload
  },
  SET_ID(state, payload) {
    state.ids = payload
  },
  UPDATE_ORGANIZATION_ID(state, payload) {
    state.userDetail.organization_id = payload
  },
  UPDATE_AVATAR(state, payload) {
    state.userDetail.avatar = payload
  },
  UPDATE_INFO(state, payload) {
    state.info = payload
  },
  DISMISS_INFO(state) {
    state.info.status = false
  },
  UPDATE_RESET_TOKEN_STATUS(state, payload) {
    state.resetToken = payload
  },
  SET_USER_LIST(state, payload) {
    state.user[payload.role] = payload.data
  },
  UPDATE_USER_LIST(state, payload) {
    state.user[payload.role].push(...payload.data)
  },
}

const mappingUserErrorMessage = (messages) => {
  let error = ''
  const modelError = messages?.active_model_errors

  if (modelError) {
    const keys = Object.keys(modelError)

    if (keys?.length) {
      error = modelError[keys[0]][0] || JSON.stringify(modelError)
    }
  } else {
    error = messages
  }
  return error
}

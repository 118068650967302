import { getField, updateField } from 'vuex-map-fields'
import requests from '~/requests'
import {
  TICKET_URL,
  INTEGRATION_QONTAK_CRM,
} from '@/assets/variables/endpoints'

export const state = () => ({
  settings: {
    is_active: false,
    title_text: '',
    button_text: '',
    webhook_url: '',
  },
  isSettingsAvailable: false,
})

export const getters = {
  getField,
}

export const actions = {
  getTicketSettings({ commit, state }, params) {
    commit('layouts/UPDATE_CONTENTLOADING', { status: true }, { root: true })

    requests.whatsapp
      .getService(`${TICKET_URL}`, params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { status: false },
            { root: true }
          )

          if (res.data && Object.keys(res.data).length > 0) {
            commit('SET_SETTING_AVAILABILITY', true)
            commit('SET_SETTINGS_DATA', res.data)
          } else {
            commit('SET_SETTING_AVAILABILITY', false)
          }
        },
        () => {
          commit('SET_SETTING_AVAILABILITY', false)
        }
      )
  },
  addTicketSettings({ commit, state }, params) {
    commit('layouts/UPDATE_CONTENTLOADING', { status: true }, { root: true })

    requests.whatsapp
      .postService(`${TICKET_URL}/settings`, state.settings, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { status: false },
            { root: true }
          )
          commit('SET_SETTING_AVAILABILITY', true)
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'success',
              message: 'Successfully Updated!',
              item: '',
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
        },
        (err) => {
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { status: false },
            { root: true }
          )
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
        }
      )
  },
  updateTicketSettings({ commit, state }, params) {
    commit('layouts/UPDATE_CONTENTLOADING', { status: true }, { root: true })

    requests.whatsapp
      .putService(`${TICKET_URL}/settings`, state.settings, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { status: false },
            { root: true }
          )
          commit('SET_SETTING_AVAILABILITY', true)
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'success',
              message: 'Successfully Updated!',
              item: '',
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
        },
        (err) => {
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { status: false },
            { root: true }
          )
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
        }
      )
  },
  getQontakCrmTemplate({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(`${INTEGRATION_QONTAK_CRM}/template`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            resolve(res)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  getDetailQontakTemplate({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(
          `${INTEGRATION_QONTAK_CRM}/template/${params.id}`,
          {},
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            resolve(res)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  getQontakContact({ commit, state }, params) {
    if (!params.query) delete params.query
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(`${INTEGRATION_QONTAK_CRM}/contacts`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            resolve(res)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  createQontakTicket({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .postService(
          `${INTEGRATION_QONTAK_CRM}/ticket/${params.id}/create`,
          params.params,
          {
            Authorization: this.$auth.getToken('hub'),
            'content-type': 'application/json',
          }
        )
        .subscribe(
          (res) => {
            resolve(res)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  getQontakRoomTicket({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(
          `${INTEGRATION_QONTAK_CRM}/room/${params.room_id}/ticket`,
          {},
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            resolve(res)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  getQontakCrmUsers({ commit, state }, params) {
    if (!params.query) delete params.query
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(`${INTEGRATION_QONTAK_CRM}/crm/users`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            resolve(res)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  getQontakIntegrationUniq({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(`${INTEGRATION_QONTAK_CRM}/integration/uniq`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            resolve(res)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  getRoomCrmDeal({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(
          `${INTEGRATION_QONTAK_CRM}/room/${params.roomId}/deal`,
          {},
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            resolve(res)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  getCrmMyMobileToken({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(
          `${INTEGRATION_QONTAK_CRM}/crm/my_mobile_token`,
          {},
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            resolve(res)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
}

export const mutations = {
  updateField,
  SET_SETTINGS_DATA(state, payload) {
    state.settings = payload
  },
  SET_SETTING_AVAILABILITY(state, payload) {
    state.isSettingsAvailable = payload
  },
}

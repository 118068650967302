import _ from 'lodash-core'
import requests from '~/requests'

export const state = () => ({
  packs: [],
  invoices: [],
  invoice: {},
  pack: {},
})

export const actions = {
  getPacks({ commit, state }, params = {}) {
    requests.packs
      .getAll(params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (response) => {
          commit('SET_PACKS', response.data.packs)
        },
        (errors) => {},
        () => {}
      )
  },
  postOrder({ state }) {
    const params = {
      orders: [
        {
          order_id: state.invoice.order.id,
          order_type: state.invoice.order.type,
          quantity: state.invoice.order.quantity,
        },
      ],
    }
    requests.orders
      .postOrder(params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (response) => {},
        (errors) => {},
        () => {}
      )
  },
  getInvoices({ commit }, params) {
    requests.invoices
      .getInvoices(params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (response) => {
          commit('SET_INVOICES', response.data.invoices)
        },
        (errors) => {},
        () => {}
      )
  },
}

export const mutations = {
  SET_PACKS(state, params) {
    state.packs = params
  },
  SET_INVOICES(state, params) {
    state.invoices = params
  },
  CURRENT_PLAN(state, params) {
    state.pack = params
    if (state.pack.amount !== undefined) {
      if (state.pack.amount === null) {
        state.pack.amount = {}
      }
      state.invoice = {
        number: '-',
        created_at: '-',
        payment_due: '-',
        subtotal: state.pack.amount.value,
        tax: '-',
        total: state.pack.amount.value,
        status: 'unpaid',
        currency: state.pack.amount.currency,
        sender: {
          full_name: 'PT. QONTAK TUMBUH MAKMUR',
          address:
            'SCBD, District 8, Prosperity Tower Lt. 3 Jl. Jend. Sudirman No.52-53 Senayan DKI Jakarta 12190',
        },
        recipient: {
          full_name: this.$auth.user.full_name,
          email: this.$auth.user.email,
        },
        order: {
          id: state.pack.id,
          type: 'pack',
          quantity: 1,
          name: state.pack.name,
          description: state.pack.description,
          total: state.pack.amount.value,
          order_items: state.pack.products,
        },
      }
    }
  },
  REINDEX_PLANS(state, params) {
    const rawPlan = []
    _.forEach(state.packs, (pack) => {
      if (pack.id === params.id) {
        pack.active = true
      }
      rawPlan.push(pack)
    })
    state.packs = rawPlan
  },
}

export const getters = {
  amountFormat(state) {
    return (amount, currency) => {
      try {
        return new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency,
        }).format(amount)
      } catch {
        return ''
      }
    }
  },
}

import _ from 'lodash'
import requests from '../requests'
import { WHATSAPP_URL, INTEGRATION_URL } from '@/assets/variables/endpoints'

export const state = () => ({
  organization: '',
  categories: [],
  languages: [],
  wacontacts: [],
  contacts: [],
  credentials: [],
  auto_resolve: {
    is_active: false,
    period: 0,
  },
  agentCanAssign: null,
  templates: [],
  variables: [],
  hsmTemplateLists: [],
  dataTable: [
    {
      category: '',
      components: [],
      language: '',
      name: '',
      status: '',
    },
  ],
  templateData: {
    buttons: [],
  },
  suggestMessageTemplate: [],
  connectToFacebook: true,
  useNewPricing: false,
  authPlaceholder: [],
})

export const actions = {
  getTemplateLists({ commit, state }, params) {
    return new Promise((resolve) => {
      requests.whatsapp
        .getService(`${WHATSAPP_URL.template}/whatsapp`, params.parameter, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit('SET_TEMPLATES', res.data)
            commit(
              'infinite/SET_META',
              { data: res.meta, source: params.source },
              { root: true }
            )
            resolve(res.data)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
          }
        )
    })
  },
  getTemplateListsByPage({ commit, state }, params) {
    return new Promise((resolve) => {
      requests.whatsapp
        .getService(`${WHATSAPP_URL.template}/whatsapp`, params.parameter, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit('PUSH_TEMPLATES', res.data)
            commit(
              'infinite/SET_META',
              { data: res.meta, source: params.source },
              { root: true }
            )
            resolve(res.data)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
          }
        )
    })
  },
  getCategories({ commit, state }, params) {
    requests.whatsapp
      .getService(
        state.useNewPricing
          ? WHATSAPP_URL.category_v1_1
          : WHATSAPP_URL.category,
        { limit: 10000 },
        {}
      )
      .subscribe(
        (res) => {
          if (params.hsm)
            res.data = res.data.filter(
              (data) => data.value !== 'AUTHENTICATION'
            )
          commit('SET_CATEGORIES', res.data)
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
        }
      )
  },
  getLanguages({ commit, state }, params) {
    requests.whatsapp
      .getService(
        WHATSAPP_URL.language,
        { limit: 10000 },
        { Authorization: this.$auth.getToken('hub') }
      )
      .subscribe(
        (res) => {
          const example = {
            file: '',
            modalFile: '',
            name: '',
            modalName: '',
            preview: '',
            modalPreview: '',
            id: '',
          }
          const media = {
            type: '',
            item: '',
          }
          const variables = []
          const attributes = []
          res.data.forEach((el) => {
            const authPlaceholder = state.authPlaceholder.filter(
              (lang) => lang.language === el.code
            )
            let emptyLangPlaceholder = false
            if (authPlaceholder.length > 0) {
              if (!authPlaceholder[0].body) emptyLangPlaceholder = true
            }
            attributes.push({
              ...el,
              authPlaceholder,
              content: '',
              buttons: [],
              codeDelivery: '',
              buttonName: emptyLangPlaceholder
                ? 'Copy code'
                : authPlaceholder.length > 0
                ? authPlaceholder[0].button_text
                : 'Copy Code',
              securityRecommendation: false,
              isExpiry: false,
              expiryTime: 10,
              media,
              example,
              variables,
            })
          })
          commit('SET_LANGUAGES', attributes)
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
        }
      )
  },
  getContacts({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(
          INTEGRATION_URL,
          { limit: 100, ...params },
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            let filter = _.filter(res.data, function (data) {
              return (
                data.target_channel === 'wa' ||
                data.target_channel === 'wa_cloud'
              )
            })
            filter = filter.map((obj) => ({
              ...obj,
              value: obj.settings.account_name,
            }))
            commit('SET_WACONTACTS', filter)
            resolve(true)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            reject(err)
          }
        )
    })
  },
  getTemplates({ commit, state }, params) {
    if (params.offset === 1) params.is_counted = true
    commit(
      'layouts/UPDATE_CONTENTLOADING',
      { type: 'table', status: true },
      { root: true }
    )
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(`${WHATSAPP_URL.template}/whatsapp`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            if (params.offset === 1)
              commit('layouts/SET_TOTAL_COUNT', res.meta.pagination.total, {
                root: true,
              })
            commit('SET_DATATABLE', res.data)
            commit('layouts/SET_META', res.meta, { root: true })
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { type: 'table', status: false },
              { root: true }
            )
            resolve(true)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { type: 'table', status: false },
              { root: true }
            )
            reject(err)
          }
        )
    })
  },
  getTemplateDetail({ commit, state }, params) {
    commit(
      'layouts/UPDATE_CONTENTLOADING',
      { type: 'content', status: true },
      { root: true }
    )
    requests.whatsapp
      .getService(
        `${WHATSAPP_URL.template}/${params}/whatsapp`,
        {},
        { Authorization: this.$auth.getToken('hub') }
      )
      .subscribe(
        (res) => {
          if (!res.data.header) {
            res.data.header = {
              format: '',
            }
          }
          commit('SET_TEMPLATEDATA', res.data)
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { type: 'content', status: false },
            { root: true }
          )
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { type: 'content', status: false },
            { root: true }
          )
        }
      )
  },
  deleteTemplate({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .deleteService(`${WHATSAPP_URL.template}/whatsapp`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'success',
                message: 'Successfully Deleted!',
                item: '',
                callback: {
                  text: 'OK',
                  method: params.hsm
                    ? 'redirectToHsmTemplate'
                    : 'redirectToTemplate',
                },
              },
              { root: true }
            )
            resolve(res.data)
          },
          (err) => {
            err = err.error.messages[0]
            let errMsg = ''
            let errMsgTitle = ''
            if (errMsg.error?.error_user_title) {
              errMsg = err.error.error_user_msg
              errMsgTitle = err.error.error_user_title
            }
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: errMsgTitle || 'Error',
                item: errMsg || err,
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            reject(err)
          }
        )
    })
  },
  createTemplate({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      const templateEndpoint = params.hsm
        ? `${WHATSAPP_URL.template}/whatsapp/hsm_chat`
        : `${WHATSAPP_URL.template}/whatsapp`
      requests.whatsapp
        .postService(templateEndpoint, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            resolve(res.data.data.length ? res.data.data[0] : null)
          },
          (err) => {
            err = err.error.messages[0]
            let errMsg = ''
            if (err.error?.error_user_title) {
              errMsg = err.error.error_user_msg
            }
            reject(errMsg || err)
          }
        )
    })
  },
  getCredential({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(
          WHATSAPP_URL.info,
          {},
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit('SET_ORGANIZATION', res.data)
            commit('SET_CREDENTIAL', res.data)
            commit('SET_AUTO_RESOLVE', res.data.auto_resolve)
            commit(
              'SET_AGENT_CAN_ASSIGN',
              res.data.settings.agent_can_assign_agent
            )
            commit(
              'agent/UPDATE_AGENT_IDLE_RULE_ASSIGNMENT',
              {
                period: res?.data?.settings?.agent_idle_rule_assignment?.period,
                enabled:
                  res?.data?.settings?.agent_idle_rule_assignment?.enabled,
              },
              { root: true }
            )
            commit(
              'inbox/UPDATE_SURVEY_FLOW',
              res?.data?.settings?.survey_flow,
              { root: true }
            )
            resolve(res)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            reject(err)
          }
        )
    })
  },
  getSuggestMessageTemplate({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(`${WHATSAPP_URL.template}/suggest_message`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit(
              params.offset > 1
                ? 'PUSH_SUGGESTMESSAGE_TEMPLATE'
                : 'SET_SUGGESTMESSAGE_TEMPLATE',
              res.data
            )
            resolve(res.data.length)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            reject(err)
          }
        )
    })
  },
  getHsmTemplateLists({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(`${WHATSAPP_URL.template}/whatsapp`, params.parameter, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit('SET_HSM_TEMPLATE_LISTS', res.data)
            commit(
              'infinite/SET_META',
              { data: res.meta, source: params.source },
              { root: true }
            )
            resolve(true)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            reject(err)
          }
        )
    })
  },
  getHsmTemplateListsByPage({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(`${WHATSAPP_URL.template}/whatsapp`, params.parameter, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit('PUSH_HSM_TEMPLATE_LISTS', res.data)
            commit(
              'infinite/SET_META',
              { data: res.meta, source: params.source },
              { root: true }
            )
            resolve(res)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            reject(err)
          }
        )
    })
  },
  uploadTemplateExample({ commit, state }, data) {
    const formdata = new FormData()
    formdata.append('file', data.file)
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .postService(`${WHATSAPP_URL.template}/whatsapp/example`, formdata, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            const payload = {
              name: data.name,
              data: res.data,
            }
            commit('UPDATE_SAMPLE_CONTENT', payload)
            resolve(res.data)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err?.error?.messages[0] || '',
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            reject(err)
          }
        )
    })
  },
  getAuthenticationPlaceholder({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(
          `${WHATSAPP_URL.template}/whatsapp/placeholder_wording_authentication`,
          {},
          {
            Authorization: this.$auth.getToken('hub'),
          }
        )
        .subscribe(
          (res) => {
            commit('SET_AUTHENTICATION_PLACEHOLDER', res.data)
            resolve(res.data)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            reject(err)
          }
        )
    })
  },
}

export const mutations = {
  SET_ORGANIZATION(state, payload) {
    state.organization = payload
  },
  SET_CATEGORIES(state, payload) {
    state.categories = payload
  },
  SET_LANGUAGES(state, payload) {
    state.languages = _.sortBy(payload, (c) => c.language)
  },
  SET_WACONTACTS(state, payload) {
    state.wacontacts = payload
  },
  SET_TEMPLATES(state, payload) {
    payload.forEach((data) => {
      if (data.buttons.length) {
        data.buttons = data.buttons.map((button) => {
          const obj = Object.assign({}, button)
          if (button.type !== 'PHONE_NUMBER') {
            obj.details = button.type === 'QUICK_REPLY' ? button.text : ''
          }
          return obj
        })
      }
      data.value = `(${data.language}) ${data.name}`
    })
    state.templates = payload
  },
  SET_TEMPLATEDATA(state, payload) {
    state.templateData = payload
  },
  SET_AUTO_RESOLVE(state, payload) {
    state.auto_resolve = payload
  },
  SET_CREDENTIAL(state, payload) {
    state.credentials = [payload]
  },
  SET_DATATABLE(state, payload) {
    state.dataTable = payload
  },
  SET_SUGGESTMESSAGE_TEMPLATE(state, payload) {
    payload.forEach((item) => {
      item.content_code =
        item.short_code + ' |%| ' + item.content.replace(/\//g, '|*|')
    })
    state.suggestMessageTemplate = payload
  },
  SET_EXAMPLE_ID(state, payload) {
    state.exampleId = payload.h
  },
  PUSH_SUGGESTMESSAGE_TEMPLATE(state, payload) {
    payload.forEach((item) => {
      item.content_code =
        item.short_code + ' |%| ' + item.content.replace(/\//g, '|*|')
      state.suggestMessageTemplate.push(item)
    })
  },
  UPDATE_VARIABLE(state, payload) {
    state.variables = payload
  },
  UPDATE_CONTENT(state, payload) {
    const content = state.languages.find(
      (cont) => cont.language === payload.name
    )
    content.content = payload.text
  },
  UPDATE_VARIABLE_CONTENT(state, payload) {
    const content = state.languages.find(
      (cont) => cont.language === payload.name
    )
    content.variables[payload.idx].content = payload.text
  },
  UPDATE_CTA_CONTENT(state, payload) {
    const content = state.languages.find(
      (cont) => cont.language === payload.name
    )
    content.buttons[payload.idx].ctaType.content = payload.text
  },
  UPDATE_VARIABLES(state, payload) {
    const content = state.languages.find(
      (cont) => cont.language === payload.name
    )
    content.variables = []
    if (payload.variables)
      payload.variables.forEach((variable) => {
        content.variables.push({
          id: variable,
          content: '',
        })
      })
    else content.variables = null
  },
  UPDATE_MEDIA(state, payload) {
    const content = state.languages.find(
      (cont) => cont.language === payload.name
    )
    content.media = {
      type: payload.type,
      item: payload.item,
    }
    content.example = {
      file: '',
      modalFile: '',
      name: '',
      modalName: '',
      preview: '',
      modalPreview: '',
      id: '',
    }
  },
  UPDATE_MEDIA_FILE(state, payload) {
    const content = state.languages.find(
      (cont) => cont.language === payload.name
    )
    content.example.modalFile = payload.file
    content.example.modalName = payload.fileName
    content.example.modalPreview = payload.preview
  },
  REMOVE_MEDIA_FILE(state, payload) {
    const content = state.languages.find(
      (cont) => cont.language === payload.name
    )
    if (content.example.id === '') {
      content.example = {
        file: '',
        modalFile: '',
        name: '',
        modalName: '',
        preview: '',
        modalPreview: '',
        id: '',
      }
    } else {
      content.example.modalName = content.example.name
      content.example.modalFile = content.example.file
      content.example.modalPreview = content.example.preview
    }
  },
  UPDATE_SAMPLE_CONTENT(state, payload) {
    const content = state.languages.find(
      (cont) => cont.language === payload.name
    )
    if (content) {
      content.example.id = payload.data.h
      content.example.preview = content.example.modalPreview
      content.example.file = content.example.modalFile
      content.example.name = content.example.modalName
    }
  },
  UPDATE_INTERACTIVE_BUTTON(state, payload) {
    let callToActionType = 'PHONE_NUMBER'
    const content = state.languages.find(
      (cont) => cont.language === payload.name
    )
    if (!payload.isPickedFirst) {
      if (content.buttons[0].type === 'PHONE_NUMBER') {
        callToActionType = 'URL'
      }
    } else content.buttons = []
    content.buttons.push({
      type: payload.type === 'QUICK_REPLY' ? payload.type : callToActionType,
      text: `Button ${content.buttons.length + 1}`,
      details: null,
      ctaType: {
        type: 'static',
        content: '',
      },
    })
  },
  RESET_INTERACTIVE_BUTTON(state, payload) {
    const content = state.languages.find(
      (cont) => cont.language === payload.name
    )
    content.buttons = []
  },
  REMOVE_AN_INTERACTIVE_BUTTON(state, payload) {
    const content = state.languages.find(
      (cont) => cont.language === payload.name
    )
    content.buttons.splice(payload.idx, 1)
  },
  UPDATE_CALL_TO_ACTION_TYPE(state, payload) {
    const content = state.languages.find(
      (cont) => cont.language === payload.name
    )
    if (content.buttons.length > 1) {
      if (payload.idx === 1) {
        if (payload.value === 'URL') {
          content.buttons[0].type = 'PHONE_NUMBER'
          content.buttons[payload.idx].type = payload.value
        } else {
          content.buttons[0].type = 'URL'
          content.buttons[payload.idx].type = payload.value
        }
      } else if (payload.idx !== 1) {
        if (payload.value === 'URL') {
          content.buttons[1].type = 'PHONE_NUMBER'
          content.buttons[payload.idx].type = payload.value
        } else {
          content.buttons[1].type = 'URL'
          content.buttons[payload.idx].type = payload.value
        }
      }
    } else {
      content.buttons[payload.idx].type = payload.value
    }
  },
  UPDATE_BUTTON_TEXT(state, payload) {
    const content = state.languages.find(
      (cont) => cont.language === payload.name
    )
    content.buttons[payload.idx].text = payload.text
  },
  UPDATE_BUTTON_DETAILS(state, payload) {
    const content = state.languages.find(
      (cont) => cont.language === payload.name
    )
    content.buttons[payload.idx].details = payload.text
  },
  PUSH_HSM_TEMPLATE_LISTS(state, payload) {
    payload.forEach((payload) => {
      state.hsmTemplateLists.push(payload)
    })
  },
  SET_HSM_TEMPLATE_LISTS(state, payload) {
    state.hsmTemplateLists = payload
  },
  CLEAR_HSM_TEMPLATE_LISTS(state) {
    state.hsmTemplateLists = []
  },
  PUSH_TEMPLATES(state, payload) {
    payload.forEach((data) => {
      if (data.buttons.length) {
        data.buttons = data.buttons.map((button) => {
          const obj = Object.assign({}, button)
          if (button.type !== 'PHONE_NUMBER') {
            obj.details = button.type === 'QUICK_REPLY' ? button.text : ''
          }
          return obj
        })
      }
    })
    payload.forEach((data) => {
      state.templates.push(data)
    })
  },
  UPDATE_URL_BUTTON_ON_INTERACTIVE_TEMPLATE(state, payload) {
    const content = state.templates.find((cont) => cont.id === payload.id)
    content.buttons[payload.idx].details = payload.text
  },
  CHANGE_CTA_TYPE(state, payload) {
    const content = state.languages.find(
      (cont) => cont.language === payload.name
    )
    content.buttons[payload.idx].ctaType.type =
      content.buttons[payload.idx].ctaType.type === 'static'
        ? 'dynamic'
        : 'static'
  },
  UPDATE_IS_CONTACT_MASKING(state, payload) {
    state.credentials[0].settings.is_contact_masking = payload
  },
  SET_AGENT_CAN_ASSIGN(state, payload) {
    state.agentCanAssign = payload
  },
  UPDATE_NEW_FB_PRICING_USAGE(state, payload) {
    state.useNewPricing = payload
  },
  SET_AUTHENTICATION_PLACEHOLDER(state, payload) {
    state.authPlaceholder = payload
  },
  UPDATE_LANGUAGE_PARAMETER(state, payload) {
    const content = state.languages.find(
      (cont) => cont.language === payload.language
    )
    if (content) content[payload.parameter] = payload.value
  },
}

export const getters = {
  getCredential(state) {
    return state.credentials
  },
}
